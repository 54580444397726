<template>
    <Export
        :date_type="null"
        :date_filter="true"
        :entities="entities"
        :campus_filter="true"
        :campuses="campuses"
        :status_filter="true"
        :statuses="statuses"
        :title="'Leave Report'"
    ></Export>
</template>


<script>
import Export from "@/components/exports/Export";
import Campus from "@/models/Campus";
import { LeaveRequestModule } from "@/store/modules/LeaveRequest";


export default {
  name: "leaves",
  components: {
    Export,
  },
  data() {
    return {
      entities: "leaves",
    };
  },
  computed: {
    campuses() {
      return Campus.query().get()
    },
    statuses(){
        return LeaveRequestModule.state.statuses
    },
  },
  mounted() {

    Campus.FetchAll({page: 1, limit: 99})
  },
};
</script>
